import React, { useState } from 'react'
import styled from 'styled-components'
import { HeroImageTitle } from 'components/new/hero'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import Content from 'components/new/content'
import BreadcrumbsScript from 'components/scripts/breadcrumbs'
import ProductCategoryGrid from 'components/new/product-category-grid'
import { H2, H3, P } from '~/components/new/typography'
import Ul from '~/components/new/ul'
import Spacer from 'components/new/spacer'

const copy = {
  telehandlers: (
    <React.Fragment>
      <H2>Discover the power of Manitou Telehandlers at Hutson, Inc.</H2>
      <P>
        At Hutson, Inc., we’re proud to offer a wide selection of Manitou telehandlers, designed to
        elevate your productivity and efficiency across various applications. Whether you’re working
        in construction, agriculture, or material handling, Manitou telehandlers deliver unmatched
        performance and versatility.
      </P>
      <Spacer size='m' />
      <H3>Key features:</H3>
      <Ul>
        <li>
          Precision Handling: Manitou telehandlers are engineered for precise load placement, making
          them ideal for tasks such as stacking, lifting, and reaching.
        </li>
        <li>
          Robust Build: With rugged construction and durable components, these telehandlers
          withstand demanding work environments.
        </li>
        <li>
          Telescopic Reach: Enjoy extended reach capabilities with telescopic booms, allowing you to
          access elevated areas effortlessly.
        </li>
        <li>
          Operator Comfort: Ergonomically designed cabs provide comfort during long hours of
          operation, enhancing productivity.
        </li>
        <li>
          Safety First: Manitou telehandlers come equipped with safety features, ensuring peace of
          mind for operators and site personnel.
        </li>
      </Ul>
      <Spacer size='m' />
      <H3>Why Choose Manitou Telehandlers?</H3>
      <Ul>
        <li>
          Versatility: From moving materials on construction sites to handling bales on the farm,
          Manitou telehandlers adapt to diverse tasks.
        </li>
        <li>
          Reliability: Built to last, these machines offer consistent performance, minimizing
          downtime.
        </li>
        <li>
          Expert Support: As an authorized Manitou equipment dealership, we provide expert guidance,
          maintenance, and genuine parts.
        </li>
      </Ul>
      <Spacer size='m' />
      <P>
        Experience the Manitou difference at Hutson, Inc. Explore our range of telehandlers today
        and elevate your operations!
      </P>
    </React.Fragment>
  ),
  forklifts: (
    <React.Fragment>
      <H2>Elevate Your Operations with Manitou Forklifts at Hutson, Inc.</H2>
      <P>
        At Hutson, Inc., we’re excited to offer Manitou forklifts, renowned for their innovation,
        versatility, and robust performance. As an authorized Manitou dealership, we understand that
        efficiency and reliability are paramount in your operations. That’s why we provide a range
        of Manitou forklifts that are perfect for any challenge, from industrial warehouses to
        rugged outdoor environments.
      </P>
      <Spacer size='m' />
      <H3>Key Features:</H3>
      <Ul>
        <li>
          All-Terrain Capability: With exceptional crossing capacity, Manitou forklifts excel on all
          types of ground, ensuring seamless operation no matter the terrain.
        </li>
        <li>
          Operator Safety and Comfort: Prioritizing maximum safety and ergonomic design, these
          forklifts offer a comfortable operator experience with 360-degree visibility from the cab.
        </li>
        <li>
          Versatile Performance: Whether you need electric, diesel, or LPG power, Manitou’s diverse
          range meets the unique demands of various industries.
        </li>
        <li>
          Ease of Maintenance: Designed with serviceability in mind, Manitou forklifts ensure easy
          maintenance, keeping your operations running smoothly.
        </li>
      </Ul>
      <Spacer size='m' />
      <H3>Why Choose Manitou Forklifts?</H3>
      <Ul>
        <li>
          Durability: Built to last, Manitou forklifts promise long-term reliability and consistent
          performance.
        </li>
        <li>
          Customization: A wide selection of attachments and mast options allows you to tailor your
          forklift to your specific needs.
        </li>
        <li>
          Expert Support: Our knowledgeable team at Hutson, Inc. is always ready to provide expert
          advice, maintenance services, and genuine parts.
        </li>
      </Ul>
      <Spacer size='m' />
      <P>
        Discover the full potential of Manitou forklifts at Hutson, Inc. and take your material
        handling to the next level!
      </P>
    </React.Fragment>
  ),
}

const ManitouSubcategory = props => {
  const {
    data: {
      sanityManitouEquipmentCategory,
      sanityManitouEquipmentSubcategory,
      allSanityManitouEquipmentSeries,
    },
  } = props

  const [{ breadcrumbs, meta, seriesData }] = useState(() => {
    const breadcrumbs = [
      {
        name: 'Home',
        schemaName: 'Hutson Inc',
        link: '/',
      },
      {
        name: 'Manitou',
        link: '/manitou/',
      },
      {
        name: sanityManitouEquipmentCategory.title,
        link: `/manitou/${sanityManitouEquipmentCategory.handle}/`,
      },
      {
        name: sanityManitouEquipmentSubcategory.title,
        link: `/manitou/${sanityManitouEquipmentCategory.handle}/${sanityManitouEquipmentSubcategory.handle}/`,
      },
    ]

    const meta = {
      title: `Manitou ${sanityManitouEquipmentSubcategory.title} | Hutson Inc`,
      description: `Check out Manitou ${sanityManitouEquipmentSubcategory.title} at Hutson.`,
      keywords: [
        sanityManitouEquipmentCategory.title,
        sanityManitouEquipmentSubcategory.title,
      ].toString(),
    }

    const seriesData = allSanityManitouEquipmentSeries.nodes.map(node => {
      return {
        link: `/manitou/${sanityManitouEquipmentCategory.handle}/${sanityManitouEquipmentSubcategory.handle}/${node.handle}/`,
        image: node.thumbnail.asset.gatsbyImageData,
        title: node.title,
        imageAlt: `Manitou ${node.title}`,
      }
    })

    return { breadcrumbs, meta, seriesData }
  })
  return (
    <Layout>
      <Helmet>
        <title>{meta.title}</title>
        <meta name='description' content={meta.description} />
        <meta name='keywords' content={meta.keywords} />
      </Helmet>

      <BreadcrumbsScript breadcrumbs={breadcrumbs} />

      <Hero
        title={`Manitou ${sanityManitouEquipmentSubcategory.title}`}
        image={sanityManitouEquipmentSubcategory.heroImageDesktop.asset.gatsbyImageData}
        breadcrumbs={breadcrumbs}
        overlayOpacity={0.15}
      />

      <Content kind='full'>
        <ProductCategoryGrid categories={seriesData} />
      </Content>
      {copy[sanityManitouEquipmentSubcategory.handle] ? (
        <LightBackground>
          <Content kind='full'>{copy[sanityManitouEquipmentSubcategory.handle]}</Content>
        </LightBackground>
      ) : null}
    </Layout>
  )
}

const Hero = styled(HeroImageTitle)`
  height: 400px;
`

const LightBackground = styled.div`
  background-color: ${props => props.theme.color.n20};
`

export const pageQuery = graphql`
  query manitouSubcategoryTemplate($category: String!, $subcategory: String!) {
    sanityManitouEquipmentCategory(handle: { eq: $category }) {
      handle
      title
    }
    sanityManitouEquipmentSubcategory(handle: { eq: $subcategory }) {
      handle
      heroImageDesktop {
        asset {
          gatsbyImageData(width: 1366)
        }
      }
      title
    }
    allSanityManitouEquipmentSeries(filter: { parentCategory: { handle: { eq: $subcategory } } }) {
      nodes {
        handle
        thumbnail {
          asset {
            gatsbyImageData(width: 240)
          }
        }
        title
      }
    }
  }
`

export default ManitouSubcategory
